<template>
  <grille-size-input
    :value="grilleSize"
    :isValid="isGrilleSizeValid"
    label="Velikost mřížky"
    invalidFeedback="Velikost mřížky musí být v rozmezí 4-16"
    @valueChanged="grilleSizeChanged"
  />

  <cipher-text-area
    :value="cipherText"
    :isValid="isCipherTextValid"
    invalidFeedback="Nesprávná délka šifrového textu vzhledem k velikosti mřížky"
    @valueChanged="cipherTextChanged"
  />

  <button @click="solve" class="danger-btn">Prolomit</button>
  <button @click="toggleSettings" class="special-btn">Nastavení</button>

  <div v-show="settingsShown">
    <solve-settings
      :value="numOfIterations"
      @valueChanged="numOfIterationsChanged"
      :max="10000"  
    />
  </div>

  <div v-show="isSolving" class="solve-alert">Probíhá prolamování...</div>

  <solve-results :results="results" />
</template>

<script>
import KeyNumberInput from '@/components/input/KeyNumberInput'
import CipherTextArea from '@/components/input/CipherTextArea'
import SolveSettings from '@/components/solve/SolveSettings'
import SolveResults from '@/components/solve/SolveResults'

import { solveFleissner } from '@/solve/fleissner.worker.js'

export default {
  components: {
    'grille-size-input': KeyNumberInput,
    'cipher-text-area': CipherTextArea,
    'solve-settings': SolveSettings,
    'solve-results': SolveResults
  },
  data() {
    return {
      grilleSize: 6,
      cipherText: '',
      numOfIterations: 1000,
      results: [],
      settingsShown: false,
      isSolving: false
    }
  },
  methods: {
    grilleSizeChanged(value) {
      this.grilleSize = value
    },
    cipherTextChanged(value) {
      this.cipherText = value
      this.results = []
    },
    solve() {
      if (this.isSolving || this.numOfIterations == 0) return

      if (this.isGrilleSizeValid && this.isCipherTextValid) {
        this.isSolving = true
        solveFleissner(this.cipherText, this.grilleSize, this.numOfIterations)
          .then(result => {
            this.results.push(result)
            this.isSolving = false
          })
      }
    },
    toggleSettings() {
      this.settingsShown = !this.settingsShown
    },
    numOfIterationsChanged(value) {
      this.numOfIterations = value
    }
  },
  computed: {
    isGrilleSizeValid() {
      return this.grilleSize >= 4 && this.grilleSize <= 16
    },
    isCipherTextValid() {
      if (!this.isGrilleSizeValid) return false
      const size = this.grilleSize
      const maxTextLength = size * size - (size % 2 == 1 ? 1 : 0)
      return this.cipherText.length == maxTextLength
    }
  }
}
</script>